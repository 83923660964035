import React from "react";
import { ISvgIconProps } from "../types/SvgIcon.types";

const GetInvoiceIcon = (props: ISvgIconProps) => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="38" cy="38" r="24" fill="#0D81EC"/>
            <path
                d="M34.25 44.25H41.75M34.25 40.5H41.75M46.7495 34.25H41.2495C40.5494 34.25 40.1998 34.25 39.9324 34.1137C39.6972 33.9939 39.5061 33.8027 39.3862 33.5675C39.25 33.3001 39.25 32.9501 39.25 32.25V26.75M46.75 45.25V35.0675C46.75 34.4881 46.7503 34.1984 46.6877 33.9241C46.6323 33.6809 46.541 33.4474 46.4165 33.2312C46.276 32.9875 46.0791 32.775 45.6854 32.3498L41.6877 28.0323C41.2503 27.5599 41.0313 27.3236 40.771 27.1543C40.5403 27.0042 40.2858 26.8933 40.0189 26.826C39.7179 26.75 39.3965 26.75 38.7526 26.75H33.2502C31.8501 26.75 31.1495 26.75 30.6147 27.0225C30.1443 27.2622 29.7622 27.6446 29.5225 28.1151C29.25 28.6498 29.25 29.3499 29.25 30.75V45.25C29.25 46.6501 29.25 47.3502 29.5225 47.885C29.7622 48.3554 30.1443 48.7378 30.6147 48.9775C31.1495 49.25 31.8501 49.25 33.2502 49.25H42.7502C44.1504 49.25 44.85 49.25 45.3847 48.9775C45.8551 48.7378 46.2381 48.3554 46.4778 47.885C46.7503 47.3502 46.75 46.6501 46.75 45.25Z"
                stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default GetInvoiceIcon;