import { atom, selector } from "recoil";

export enum ModalTypes {
    profile = 'profileModal',
    financesAdd = 'financesAddModal',
    createClient = 'createClientModal',
    createCampaign = 'createCampaignModal',
}

export interface IModalState {
    type: null | ModalTypes
    isOpened: boolean;
}

export const modalState = atom<IModalState>({
    key: "modalState",
    default: {
        type: null,
        isOpened: false
    }
})

export const getIsProfileModalOpened = selector<boolean>({
    key: "isProfileModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalTypes.profile;
    }
});

export const getIsFinancesAddModalOpened = selector<boolean>({
    key: "isFinancesAddModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalTypes.financesAdd;
    }
});

export const getIsCreateClientModalOpened = selector<boolean>({
    key: "isCreateClientModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalTypes.createClient;
    }
});

export const getIsCreateCampaignModalOpened = selector<boolean>({
    key: "isCreateCampaignModalOpened",
    get: ({ get }) => {
        const modal: IModalState = get(modalState);

        return modal.isOpened && modal.type === ModalTypes.createCampaign;
    }
});