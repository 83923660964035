import React from 'react';
import "../styles/Page.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import CampaignFilters from "../../../components/Campaign/CampaignsFilters/CampaignFilters";
import CampaignsList from "../../../components/Campaign/CampaignsList/CampaignsList";
import { useNavigate } from "react-router-dom";

const CampaignsPage = () => {
    const navigate = useNavigate();
    const onButtonClick = () => {
        navigate("/campaigns/create");
    }

    return (
        <div className="page campaigns">
            <PageTop className="campaigns__top">
                <PageTitle
                    className="campaigns__title"
                    title="Мои рекламные кампании"
                    hasButton
                    buttonTitle="Создать рекламную кампанию"
                    buttonAction={onButtonClick}
                />
                <CampaignFilters />
            </PageTop>

            <CampaignsList />
        </div>
    );
}

export default CampaignsPage;
