import React from 'react';
import { IGridDataRowsState, IGridHeaderCellProps, IGridProps } from "./types/Grid.types";

import "./styles/Grid.sass";
import "./styles/Table.sass";

const Grid = (props: IGridProps) => {
    const { rowsTemplate, columnsTemplate, headers, data } = props;

    return (
        <div className="grid">
            <div className="grid__top">Показаны записи <strong>1-5 из 5</strong></div>
            <div className="grid__table table">
                <div className="table__header" style={{ gridTemplateColumns: columnsTemplate }}>
                    {headers.map((header: IGridHeaderCellProps, i: number) => (
                        <div key={i} className="table__cell">{header.title}</div>
                    ))}
                </div>
                <div className="table__body" style={{ gridTemplateColumns: columnsTemplate }}>

                </div>
            </div>
            <div className="grid__bottom"></div>
        </div>
    );
}

export default Grid;
