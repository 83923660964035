import React, { useId } from 'react';
import classNames from "classnames";
import { IFieldInputProps } from "./types/TextInput.types";

import './styles/TextInput.sass';

const TextInput = (props: IFieldInputProps) => {
    const { label, placeholder, inputType, className, isRequired, isReadOnly, name, value, onChange } = props;

    const inputId = useId();

    return (
        <div className={classNames("text-input__wrapper", className)}>
            {label && (
                <label htmlFor={inputId} className={classNames("text-input__label", { 'is-required': isRequired })}>
                    {label}
                </label>
            )}
            <input id={inputId}
                   name={name}
                   type={inputType || "text"}
                   className="text-input__input"
                   placeholder={placeholder}
                   readOnly={isReadOnly}
                   defaultValue={value}
                   onChange={onChange}
            />
        </div>
    )
}

export default TextInput;