import React from 'react';
import { ICampaignsListItemProps } from "./types/CampaignsListItem.types";
import Button from "../../UI/Button/Button";

import './styles/CampaignsListItem.sass';

const CampaignsListItem = (props: { item: ICampaignsListItemProps }) => {
    const { item } = props;

    return (
        <div className="campaigns-list-item">
            <div className="campaigns-list-item__left">
                <div className="campaigns-list-item__image">
                    <img src={item.imageUrl} alt={item.title}/>
                </div>
            </div>
            <div className="campaigns-list-item__right">
                <div className="campaigns-list-item__title">{item.title}</div>
                <div className="campaigns-list-item__description">
                    <div className="campaigns-list-item__stat">Показов всего: <strong>{item.showingTotal}</strong></div>
                    <div className="campaigns-list-item__stat">Показов сегодня: <strong>{item.showingToday}</strong></div>
                    <div className="campaigns-list-item__stat">Статус: <strong>{item.status}</strong></div>
                </div>
                <Button
                    isSecondary
                    className="campaigns-list-item__btn"
                    title="Подробнее"
                />
            </div>
        </div>
    )
}

export default CampaignsListItem;