import React from "react";
import { ISvgIconProps } from "../types/SvgIcon.types";

const SBPIcon = (props: ISvgIconProps) => {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65.1593 35.6216V43.495H62.3632V37.972H59.671V43.495H56.875V35.6211L65.1593 35.6216Z"
                  fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M51.132 43.7764C53.635 43.7764 55.4937 42.2333 55.4937 39.8939C55.4937 37.6297 54.1224 36.1596 51.8305 36.1596C50.7728 36.1596 49.9 36.534 49.2427 37.1799C49.3996 35.8448 50.5224 34.8701 51.7587 34.8701C52.044 34.8701 54.192 34.8655 54.192 34.8655L55.4067 32.5283C55.4067 32.5283 52.7099 32.59 51.4563 32.59C48.592 32.6402 46.6572 35.2581 46.6572 38.4378C46.6572 42.142 48.5442 43.7764 51.132 43.7764ZM51.1473 38.2298C52.0766 38.2298 52.7209 38.8438 52.7209 39.8936C52.7209 40.8386 52.1484 41.6171 51.1473 41.6192C50.1896 41.6192 49.5453 40.8979 49.5453 39.9097C49.5453 38.8596 50.1896 38.2298 51.1473 38.2298Z"
                  fill="black"/>
            <path
                d="M44.3813 40.8589C44.3813 40.8589 43.721 41.2416 42.7348 41.3141C41.6011 41.3479 40.5908 40.6279 40.5908 39.3485C40.5908 38.1006 41.4823 37.3854 42.7063 37.3854C43.4568 37.3854 44.4497 37.9085 44.4497 37.9085C44.4497 37.9085 45.1762 36.568 45.5525 35.8976C44.8634 35.3724 43.9455 35.0845 42.8779 35.0845C40.1836 35.0845 38.0967 36.8509 38.0967 39.3327C38.0967 41.8462 40.0581 43.5716 42.8779 43.5195C43.666 43.49 44.7534 43.2117 45.4161 42.7836L44.3813 40.8589Z"
                fill="black"/>
            <path d="M10 29.0098L13.8786 35.9806V40.2326L10.0045 47.1897L10 29.0098Z" fill="#5B57A2"/>
            <path d="M24.8911 33.444L28.5255 31.2042L35.9634 31.1973L24.8911 38.0175V33.444Z" fill="#D90751"/>
            <path d="M24.871 28.9687L24.8915 38.1978L21.0039 35.7959V22L24.871 28.9687Z" fill="#FAB718"/>
            <path d="M35.9638 31.1972L28.5256 31.2042L24.871 28.9687L21.0039 22L35.9638 31.1972Z" fill="#ED6F26"/>
            <path d="M24.8915 47.2285V42.7508L21.0039 40.3945L21.006 54.2041L24.8915 47.2285Z" fill="#63B22F"/>
            <path d="M28.5174 45.009L13.8783 35.9806L10 29.0098L35.9486 44.9999L28.5174 45.009Z" fill="#1487C9"/>
            <path d="M21.0063 54.2042L24.8913 47.2285L28.5166 45.0091L35.9478 45L21.0063 54.2042Z" fill="#017F36"/>
            <path d="M10.0044 47.1896L21.0361 40.3945L17.3273 38.1064L13.8784 40.2324L10.0044 47.1896Z" fill="#984995"/>
        </svg>
    );
};

export default SBPIcon;