import React from 'react';
import { IInfoProps } from "./types/IInfoProps.types";

import "./styles/Info.sass";
import classNames from "classnames";

const Info = (props: IInfoProps) => {
    const { children, className } = props;
    return (
        <div className={classNames("info", className)}>
            {children}
        </div>
    );
}

export default Info;
