import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const MessageIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9398 12.4131H15.9488" stroke="#4E5E6D" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M11.9301 12.4131H11.9391" stroke="#4E5E6D" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.92128 12.4131H7.93028" stroke="#4E5E6D" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19.071 19.0698C16.0159 22.1264 11.4896 22.7867 7.78631 21.074C7.23961 20.8539 3.70113 21.8339 2.93334 21.067C2.16555 20.2991 3.14639 16.7601 2.92631 16.2134C1.21285 12.5106 1.87411 7.9826 4.9302 4.9271C8.83147 1.0243 15.1698 1.0243 19.071 4.9271C22.9803 8.83593 22.9723 15.1681 19.071 19.0698Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default MessageIcon;