import React from 'react';
import PageTop from "../../../components/PageTop/PageTop";
import FinancesStat from "../../../components/Finances/FinancesStat/FinancesStat";
import FinancesRedistribution from "../../../components/Finances/FinancesRedistribution/FinancesRedistribution";
import FinancesHistory from "../../../components/Finances/FinancesHistory/FinancesHistory";

import "../styles/Page.sass";
import "./styles/Finances.sass";

const FinancesPage = () => {
    return (
        <div className="page finances">
            <PageTop className="finances__top">
                <FinancesStat />
                <FinancesRedistribution />
            </PageTop>

            <FinancesHistory />
        </div>
    );
}

export default FinancesPage;
