import React from 'react';
import { useRecoilState } from "recoil";
import { modalState, ModalTypes } from "../../../store/Modal.recoil";

import Button from "../../UI/Button/Button";

import "./styles/FinancesStat.sass";

const FinancesStat = () => {
    const [, setModal] = useRecoilState(modalState);

    const openFinancesAddModal = () => {
        console.log("openFinancesAddModal");
        setModal({
            type: ModalTypes.financesAdd,
            isOpened: true
        })
    }

    return (
        <div className="finances-stat">
            <div className="finances-stat__data">
                <div className="finances-stat__column">
                    <div className="finances-stat__title">Всего</div>
                    <div className="finances-stat__value">30 000.00 ₽</div>
                </div>
                <div className="finances-stat__column">
                    <div className="finances-stat__title">Используется</div>
                    <div className="finances-stat__value finances-stat__value-gray">27 000.00 ₽</div>
                </div>
            </div>

            <Button title="Пополнить баланс" onClick={openFinancesAddModal} />
        </div>
    );
}

export default FinancesStat;
