import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const LockOpenedIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2766 5.98785C15.7036 4.09285 13.9316 2.72485 11.8536 2.75085C9.38661 2.78085 7.39161 4.76785 7.34961 7.23485V9.40385"
                stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.9102 14.1562V16.3772" stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.91 8.82422C6.165 8.82422 4.25 10.3922 4.25 15.0952C4.25 19.7992 6.165 21.3672 11.91 21.3672C17.656 21.3672 19.571 19.7992 19.571 15.0952C19.571 10.3922 17.656 8.82422 11.91 8.82422Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default LockOpenedIcon;