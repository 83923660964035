import React from 'react';
import { IProfileSidebarItemProps } from "./types/ProfileSidebarItem.types";

import './styles/ProfileSidebarItem.sass';

const ProfileSidebarItem = (props: IProfileSidebarItemProps) => {
    const { label, value } = props;

    return (
        <div className="profile-sidebar-item">
            <div className="profile-sidebar-item__label">{label}</div>
            <div className="profile-sidebar-item__value">{value}</div>
        </div>
    );
};

export default ProfileSidebarItem;