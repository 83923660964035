import React from 'react';
import Modal from "../../UI/Modal/Modal";
import FinancesAddItem from "./FinancesAddItem/FinancesAddItem";
import BankCardIcon from "../../UI/SvgIcons/payments/BankCardIcon";
import SBPIcon from "../../UI/SvgIcons/payments/SBPIcon";
import SberBusinessIcon from "../../UI/SvgIcons/payments/SberBusinessIcon";
import GetInvoiceIcon from "../../UI/SvgIcons/payments/GetInvoiceIcon";
import { IFinancesAddItemProps } from "./FinancesAddItem/types/FinancesAddItem.types";

import './styles/FinancesAdd.sass';

const paymentsMethods: IFinancesAddItemProps[] = [
    {
        id: "card",
        icon: <BankCardIcon />,
        title: "Банковская карта",
    },
    {
        id: "sbp",
        icon: <SBPIcon />,
        title: "Система быстрых платежей",
    },
    {
        id: "business",
        icon: <SberBusinessIcon />,
        title: "СберБизнес",
    },
    {
        id: "invoice",
        icon: <GetInvoiceIcon />,
        title: "Запросить счёт",
    }
]

const FinancesAdd = (props: any) => {
    return (
        <Modal justifyContent="center">
            <div className="finances-add">
                <div className="finances-add__header">
                    <div className="finances-add__title">Пополнить счет</div>
                </div>
                <div className="finances-add__body">
                    <div className="finances-add__items">
                        {paymentsMethods.map(paymentsMethod => (
                            <FinancesAddItem
                                key={paymentsMethod.id}
                                className="finances-add__item"
                                id={paymentsMethod.id}
                                title={paymentsMethod.title}
                                icon={paymentsMethod.icon}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FinancesAdd;