import React from "react";
import "./styles/Pill.sass";
import { IPillProps } from "./types/Pill.types";
import classNames from "classnames";

const Pill = (props: IPillProps) => {
    const { title, className, onClick } = props;

    return (
        <button type="button" className={classNames('pill', className)} onClick={onClick}>
            <span className="pill__icon"></span>
            <span className="pill__title">{title}</span>
        </button>
    );
};

export default Pill;