import React, {useEffect} from 'react';

import './App.sass';

import { Outlet } from "react-router-dom";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import ProfileSidebar from "components/Profile/ProfileSidebar/ProfileSidebar";
import FinancesAdd from "./components/Finances/FinancesAdd/FinancesAdd";
import ClientsCreateModal from "./components/Client/ClientsCreateModal/ClientsCreateModal";

import API from "./api";

import { useRecoilState, useRecoilValue } from "recoil";
import {
    getIsProfileModalOpened,
    getIsFinancesAddModalOpened,
    getIsCreateClientModalOpened,
} from "./store/Modal.recoil";
import { getUserCompanyId, profileState } from "./store/Profile.recoil";
import { companyState } from "./store/Company.recoil";

const App = () => {
    const isProfileModalOpened = useRecoilValue(getIsProfileModalOpened);
    const isFinancesAddOpened = useRecoilValue(getIsFinancesAddModalOpened);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const userCompanyId = useRecoilValue(getUserCompanyId);

    const [, setProfile] = useRecoilState(profileState);
    const [, setCompany] = useRecoilState(companyState);

    useEffect(() => {
        API.profile.me().then(({ data }) => {
            const { data: profileData } = data;
            setProfile(profileData);
        })
    }, [setProfile]);

    useEffect(() => {
        if (userCompanyId) {
            API.company
                .info(userCompanyId)
                .then(({ data }) => {
                    const { data: companyData } = data;
                    setCompany(companyData);
                })
        }
    }, [userCompanyId, setCompany]);

    return (
        <div className="app">
            <Header />

            <div className="main">
                <Sidebar />
                <div className="content">
                    <Outlet />
                </div>
            </div>

            {isProfileModalOpened && (<ProfileSidebar />)}
            {isFinancesAddOpened && (<FinancesAdd />)}
            {isCreateClientModalOpened && (<ClientsCreateModal />)}
        </div>
    );
}

export default App;
