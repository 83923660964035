import React, { useId } from 'react';
import classNames from "classnames";
import TextInput from "../TextInput/TextInput";
import { IDateRangeInputProps } from "./types/DateRangeInput.types";


import './styles/DateRangeInput.sass';


const DateRangeInput = (props: IDateRangeInputProps) => {
    const { label, className } = props;

    const inputId = useId();

    return (
        <div className={classNames("data-range-input__wrapper", className)}>
            {label && <label htmlFor={inputId} className="data-range-input__label">{label}</label>}
            <div className="data-range-input__inputs">
                <TextInput
                    inputType="date"
                    placeholder="Дата начала"
                />

                <TextInput
                    inputType="date"
                    placeholder="Дата окончания"
                />
            </div>
        </div>
    )
}

export default DateRangeInput;