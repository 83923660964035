import React from 'react';
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import './styles/ProfileSidebar.sass';

import Modal from "../../UI/Modal/Modal";
import ProfileSidebarItem from "./ProfileSidebarItem/ProfileSidebarItem";
import Button from "../../UI/Button/Button";
import { companyState } from "../../../store/Company.recoil";
import { getUserEmail, getUserFullName } from "../../../store/Profile.recoil";

const ProfileSidebar = (props: any) => {
    const [company] = useRecoilState(companyState);
    const userEmail = useRecoilValue(getUserEmail);
    const userFullName = useRecoilValue(getUserFullName);

    const navigate = useNavigate();
    const onProfileEditClick = () => {
        navigate("/profile");
    }

    return (
        <Modal justifyContent="right">
            <div className="profile-sidebar">
                <div className="profile-sidebar__header">
                    <div className="profile-sidebar__title">Профиль</div>
                    <div className="profile-sidebar__name">{userFullName}</div>
                </div>

                <div className="profile-sidebar__body">
                    <ProfileSidebarItem label="ID компании" value={company.id} />
                    <ProfileSidebarItem label="Наименование компании" value={company.legalName} />
                    {userEmail !== userFullName && <ProfileSidebarItem label="E-mail" value={userEmail} />}
                </div>

                <div className="profile-sidebar__actions">
                    <Button title="Редактировать" onClick={onProfileEditClick} />
                    <Button isSecondary title="Выход" />
                </div>
            </div>
        </Modal>
    );
};

export default ProfileSidebar;