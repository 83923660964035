import React from 'react';
import { ICampaignsListProps } from "./types/CampaignsList.types";
import { ICampaignsListItemProps} from "../CampaignsListItem/types/CampaignsListItem.types";
import CampaignsListItem from "../CampaignsListItem/CampaignsListItem";

import './styles/CampaignsList.sass';

const campaignListItems: ICampaignsListItemProps[] = [
    {
        title: "Тест Лайм HD",
        imageUrl: "/assets/img/examples/campaign-item-1.png",
        status: "Активна",
        showingToday: 108963,
        showingTotal: 1042
    },
    {
        title: "Тест Лайм HD",
        imageUrl: "/assets/img/examples/campaign-item-2.png",
        status: "Активна",
        showingToday: 108963,
        showingTotal: 1042
    },
    {
        title: "Тест Лайм HD",
        imageUrl: "/assets/img/examples/campaign-item-3.png",
        status: "Активна",
        showingToday: 108963,
        showingTotal: 1042
    }
];

const CampaignsList = (props: ICampaignsListProps) => {
    return (
        <div className="campaigns-list">
            {campaignListItems.map((item: ICampaignsListItemProps, i: number) => (
                <CampaignsListItem key={i} item={item} />
            ))}
        </div>
    )
}

export default CampaignsList;