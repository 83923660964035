import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const UserIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.8445 21.6618C8.15273 21.6618 5 21.0873 5 18.7865C5 16.4858 8.13273 14.3618 11.8445 14.3618C15.5364 14.3618 18.6891 16.4652 18.6891 18.766C18.6891 21.0658 15.5564 21.6618 11.8445 21.6618Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.8375 11.1735C14.2602 11.1735 16.2239 9.2099 16.2239 6.78718C16.2239 4.36445 14.2602 2.3999 11.8375 2.3999C9.41477 2.3999 7.45022 4.36445 7.45022 6.78718C7.44204 9.20172 9.39204 11.1654 11.8066 11.1735C11.8175 11.1735 11.8275 11.1735 11.8375 11.1735Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default UserIcon;