import axios from "axios";
import profileAPI from "./services/profileAPI";
import companyAPI from "./services/companyAPI";
import suggestionAPI from "./services/suggestionAPI";
import partnerAPI from "./services/partnerAPI";

const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

class APIService {
    company = companyAPI;
    partner = partnerAPI;
    profile = profileAPI;
    suggestion = suggestionAPI;

    setToken = (token: string) => {
        axios.defaults.headers['Access-Token'] = `${token}`;
    };

    clearToken = () => {
        delete axios.defaults.headers['Access-Token'];
    };
}

const API = new APIService();
export default API;