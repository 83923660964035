import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import API from "./api";
import { getAccessToken, storeAccessToken } from "./utils/functions";

import App from "./App";
import CampaignsPage from "./routes/Pages/CampaignsPage/CampaignsPage";
import ClientsPage from "./routes/Pages/ClientsPage/ClientsPage";
import AccessPage from "./routes/Pages/AccessPage/AccessPage";
import FinancesPage from "./routes/Pages/FinancesPage/FinancesPage";
import StatisticsPage from "./routes/Pages/StatisticsPage/StatisticsPage";
import ErrorPage from "./routes/Pages/ErrorPage/ErrorPage";
import ProfilePage from "./routes/Pages/ProfilePage/ProfilePage";
import CampaignCreatePage from "./routes/Pages/CampaignCreatePage/CampaignCreatePage";

storeAccessToken();
const accessToken = getAccessToken();

if (!accessToken) {
    const redirectUrl = process.env.REACT_APP_FRONTEND_URL || 'https://vtochku.online'
    window.location.replace(redirectUrl);
}

if (accessToken) {
    API.setToken(accessToken);
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/profile",
                element: <ProfilePage />,
            },
            {
                path: "/campaigns",
                element: <CampaignsPage />,
            },
            {
                path: "/campaigns/create",
                element: <CampaignCreatePage />,
            },
            {
                path: "/clients",
                element: <ClientsPage />,
            },
            {
                path: "/access",
                element: <AccessPage />,
            },
            {
                path: "/finances",
                element: <FinancesPage />,
            },
            {
                path: "/statistics",
                element: <StatisticsPage />,
            },
        ],
        errorElement: <ErrorPage />,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLDivElement
);

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <RouterProvider router={router}/>
        </RecoilRoot>
    </React.StrictMode>
);