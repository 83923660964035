import React from 'react';
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Button from "../../UI/Button/Button";
import DateRangeInput from "../../UI/Inputs/DateRangeInput/DateRangeInput";

import "./styles/ClientsFilters.sass";

const ClientsFilters = () => {
    return (
        <div className="clients-filters">
            <TextInput
                className="filter filter-name"
                label="Рекламная кампания"
                placeholder="Введите название кампании"
            />

            <DateRangeInput
                className="filter filter-date"
                label="Период"
            />

            <Button isSecondary className="filter-button" title="Поиск" />
        </div>
    )
}

export default ClientsFilters;