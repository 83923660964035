import React from 'react';
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";

import "../styles/Page.sass";

const AccessPage = () => {
    return (
        <div className="page access">
            <PageTop className="access__top">
                <PageTitle className="access__title" title="Доступы" />
            </PageTop>
        </div>
    );
}

export default AccessPage;
