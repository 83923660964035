import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const ProfileIcon = (props: ISvgIconProps) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.6552 14.069C25.6552 18.1825 22.3205 21.5172 18.2069 21.5172C14.0933 21.5172 10.7586 18.1825 10.7586 14.069C10.7586 9.9554 14.0933 6.62069 18.2069 6.62069C22.3205 6.62069 25.6552 9.9554 25.6552 14.069Z"
                fill="#4E5E6D"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.4662 27.2139C35.0754 24.5186 36 21.3673 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 21.4584 0.975327 24.689 2.66593 27.4317C2.79769 27.6455 2.93379 27.8563 3.07412 28.064C6.30885 32.852 11.7868 36 18 36C18.0243 36 18.0485 36 18.0728 35.9999C18.0873 35.9998 18.1019 35.9997 18.1164 35.9996C24.6417 35.9583 30.3432 32.4449 33.4662 27.2139ZM31.3509 25.9128C32.7271 23.5959 33.5172 20.8902 33.5172 18C33.5172 9.43006 26.5699 2.48276 18 2.48276C9.43006 2.48276 2.48276 9.43006 2.48276 18C2.48276 20.9589 3.31094 23.7244 4.74815 26.0773C8.07798 24.2924 12.8748 23.1724 18.2069 23.1724C23.3733 23.1724 28.0371 24.2239 31.3509 25.9128Z"
                  fill="#4E5E6D"/>
        </svg>
    );
};

export default ProfileIcon;