import React from 'react';

import "../styles/Page.sass";

const ErrorPage = () => {
    return (
        <div className="error-page page">
            Oooops!
        </div>
    );
}

export default ErrorPage;
