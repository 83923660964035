import React from 'react';
import Button from "../../UI/Button/Button";

import "./styles/FinancesRedistribution.sass";

const FinancesRedistribution = () => {
    return (
        <div className="finances-redistribution">
            <div className="finances-redistribution__text">Распределение бюджета между клиентами</div>
            <div className="finances-redistribution__button">
                <Button title="Перевести" isSecondary />
            </div>
        </div>
    );
}

export default FinancesRedistribution;
