import React from 'react';
import classNames from "classnames";
import { IPageTopProps } from "./types/PageTop.types";

import "./styles/PageTop.sass";

const PageTop = (props: IPageTopProps) => {
    const { children, className } = props;

    return (
        <div className={classNames('page-top', className)}>
            { children }
        </div>
    );
}

export default PageTop;
