import React, {ChangeEvent, useState} from 'react';

import "./styles/ClientsCreateModal.sass";

import Modal from 'components/UI/Modal/Modal';
import Button from "../../UI/Button/Button";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import { ClientFormType } from "../../../models/ClientFormTypes";
import Pill from "../../UI/Pill/Pill";
import classNames from "classnames";
import { ICompanySaveRequest } from "../../../api/services/companyAPI";
import SearchInput from "../../UI/Inputs/SearchInput/SearchInput";
import API from "../../../api";

const ClientsCreateModal = () => {
    const [legalType, setLegalType] = useState(ClientFormType.UL);
    const [values, setValues] = useState<ICompanySaveRequest>({
        formType: ClientFormType.UL,
        firstName: "",
        middleName: "",
        lastName: "",
        legalName: "",
        inn: "",
        contract: "",
        comment: ""
    });

    const isPillActive = (selector: ClientFormType): boolean => {
        return legalType === selector;
    }

    const changeLegalType = (value: ClientFormType): void => {
        setLegalType(value);
        setValues({
            formType: value,
            firstName: "",
            middleName: "",
            lastName: "",
            legalName: "",
            inn: "",
            contract: "",
            comment: ""
        })
    }

    const onNonNumericValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const { data } = e.nativeEvent as InputEvent;
        if (/[0-9]/.test(String(data))) {
            onValueChanged(e);
        }
    }

    const onValueChanged = (e: any) => {
        const newValues = {
            ...values,
            [e.target.name]: e.target.value
        }

        setValues(newValues);
    }

    const renderCompanySuggestion = (item: any): string=> {
        const { data } = item;

        return data.name.short_with_opf + ', ИНН: ' + data.inn;
    }

    const selectCompanySuggestion = (item: any)=> {
        const { data } = item;

        const newValues = {
            ...values,
            legalName: data.name.short_with_opf,
            inn: data.inn
        }

        setValues(newValues);
    }

    const onCreateButtonClick = () => {
        API.partner.user(values)
            .then(({ data }) => {
                console.log(data);
            })
    }

    return (
        <Modal justifyContent="center" className="clients-create-modal">
            <div className="clients-create-modal__content">
                <div className="clients-create-modal__header">
                    <div className="clients-create-modal__title">Создание клиента</div>
                </div>
                <div className="clients-create-modal__body">
                    <div className="clients-create-modal__types">
                        <Pill
                            className={ classNames({ 'active': isPillActive(ClientFormType.UL)}) }
                            title="Юр. лицо"
                            onClick={(e) => changeLegalType(ClientFormType.UL)}
                        />
                        <Pill
                            className={ classNames({ 'active': isPillActive(ClientFormType.IP)}) }
                            title="ИП"
                            onClick={(e) => changeLegalType(ClientFormType.IP)}
                        />
                        <Pill
                            className={ classNames({ 'active': isPillActive(ClientFormType.FL)}) }
                            title="Физ. лицо"
                            onClick={(e) => changeLegalType(ClientFormType.FL)}
                        />
                    </div>
                    <div className="clients-create-modal__inputs">
                        {legalType === ClientFormType.FL && (
                            <TextInput
                                name="lastName"
                                label="Фамилия"
                                placeholder="Введите фамилию"
                                value={values.lastName}
                                onChange={onValueChanged}
                            />
                        )}

                        {legalType === ClientFormType.FL && (
                            <TextInput
                                name="firstName"
                                label="Имя"
                                placeholder="Введите имя"
                                value={values.firstName}
                                onChange={onValueChanged}
                            />
                        )}

                        {legalType === ClientFormType.FL && (
                            <TextInput
                                name="middleName"
                                label="Отчество"
                                placeholder="Введите отчество"
                                value={values.middleName}
                                onChange={onValueChanged}
                            />
                        )}

                        {[ClientFormType.UL, ClientFormType.IP].includes(legalType) && (
                            <SearchInput
                                name="legalName"
                                label="Название компании"
                                placeholder="Введите название кампании"
                                value={values.legalName}
                                suggestionApi={API.suggestion.company}
                                suggestionRenderResult={renderCompanySuggestion}
                                suggestionClickResult={selectCompanySuggestion}
                            />
                        )}

                        {[ClientFormType.UL, ClientFormType.IP].includes(legalType) && (
                            <SearchInput
                                name="inn"
                                label="ИНН"
                                placeholder="Введите ИНН"
                                value={values.inn}
                                suggestionApi={API.suggestion.company}
                                suggestionRenderResult={renderCompanySuggestion}
                                suggestionClickResult={selectCompanySuggestion}
                            />
                        )}

                        {legalType === ClientFormType.FL && (
                            <TextInput
                                name="inn"
                                label="ИНН"
                                placeholder="Введите ИНН"
                                value={values.inn}
                                onChange={onValueChanged}
                            />
                        )}

                        <div className="clients-create-modal__accent">Информация о договоре</div>
                        <TextInput
                            name="contract"
                            label="Номер договора"
                            placeholder="Введите номер договора"
                            value={values.contract}
                            onChange={onValueChanged}
                        />

                        <TextInput
                            name="comment"
                            label="Комментарий"
                            placeholder="Введите комментарий"
                            value={values.comment}
                            onChange={onValueChanged}
                        />
                    </div>
                </div>
                <div className="clients-create-modal__footer">
                    <div className="clients-create-modal__actions">
                        <Button title="Создать клиента" onClick={onCreateButtonClick} />
                    </div>
                </div>
            </div>
        </Modal>
)
}

export default ClientsCreateModal;