import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const ClientsIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.55802 21.4562C5.88602 21.4562 2.74902 20.9012 2.74902 18.6772C2.74902 16.4532 5.86602 14.4492 9.55802 14.4492C13.23 14.4492 16.366 16.4342 16.366 18.6572C16.366 20.8802 13.25 21.4562 9.55802 21.4562Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.55837 11.2773C11.9684 11.2773 13.9224 9.32332 13.9224 6.91332C13.9224 4.50332 11.9684 2.54932 9.55837 2.54932C7.14837 2.54932 5.19437 4.50332 5.19437 6.91332C5.18537 9.31532 7.12637 11.2693 9.52737 11.2773H9.55837Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.8003 10.0786C18.2033 9.70364 19.2373 8.42464 19.2373 6.90264C19.2383 5.31464 18.1113 3.98864 16.6133 3.68164"
                stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M17.46 13.6533C19.448 13.6533 21.146 15.0013 21.146 16.2043C21.146 16.9133 20.561 17.6413 19.671 17.8503"
                stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default ClientsIcon;