import axios from 'axios';

class SuggestionAPI {
    company = (q: string) => {
        return axios.get(`/suggestion/company?q=${q}`);
    }

}

const suggestionAPI = new SuggestionAPI();
export default suggestionAPI;