import React from 'react';
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";

import "../styles/Page.sass";

const StatisticsPage = () => {
    return (
        <div className="page clients">
            <PageTop className="clients__top">
                <PageTitle
                    className="clients__title"
                    title="Статистика"
                />

                <ClientsFilters />
            </PageTop>
        </div>
    );
}

export default StatisticsPage;
