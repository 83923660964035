import React from 'react';
import { IFinancesAddItemProps } from "./types/FinancesAddItem.types";

import './styles/FinancesAddItem.sass';

const FinancesAdd = (props: IFinancesAddItemProps) => {
    const { icon, title } = props;

    return (
        <button type="button" className="finances-add-item">
            <div className="finances-add-item__icon">{icon}</div>
            <div className="finances-add-item__name">{title}</div>
        </button>
    );
};

export default FinancesAdd;