import React, { ChangeEvent, useState } from 'react';
import "./styles/ProfileForm.sass";

import API from "../../../api";
import Info from "components/UI/Info/Info";
import TextInput from "components/UI/Inputs/TextInput/TextInput";
import Button from "components/UI/Button/Button";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    companyState,
    getCompanyId,
    getCompanyInn,
    getCompanyKpp,
    getCompanyLegalName,
    getCompanyLegalAddress,
    getCompanyBusinessArea,
} from "../../../store/Company.recoil";
import { getUserEmail, getUserFullName, getUserPhone } from "../../../store/Profile.recoil";
import { useNavigate } from "react-router-dom";
import { IProfileSaveRequest } from "../../../api/services/profileAPI";

const ProfileForm = () => {
    const userEmail = useRecoilValue(getUserEmail);
    const userPhone = useRecoilValue(getUserPhone);
    const userFullName = useRecoilValue(getUserFullName);

    const companyId = useRecoilValue(getCompanyId);
    const companyLegalName = useRecoilValue(getCompanyLegalName);
    const companyInn = useRecoilValue(getCompanyInn);
    const companyKpp = useRecoilValue(getCompanyKpp);
    const companyBusinessArea = useRecoilValue(getCompanyBusinessArea);
    const companyLegalAddress = useRecoilValue(getCompanyLegalAddress);

    const [, setCompany] = useRecoilState(companyState);

    const [values, setValues] = useState<IProfileSaveRequest>({
        kpp: companyKpp,
        phone: userPhone,
        businessArea: companyBusinessArea,
        fullName: userFullName,
        website: undefined,
        legalAddress: companyLegalAddress
    });

    const [isLoading, setIsLoading] = useState(false);

    const onValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const newValues = {
            ...values,
            [e.target.name]: e.target.value
        }

        setValues(newValues);
    }

    const navigate = useNavigate();
    const onCancelButtonClick = () => {
        navigate("/");
    }

    const onSaveButtonClick = (e: any) => {
        if (isLoading) return;
        if (!companyId) return;

        setIsLoading(true);

        API.profile
            .update(companyId, values)
            .then(({ data }) => {
                const { data: companyData } = data;
                setCompany(companyData);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="profile-form__wrapper">
            <div className="profile-form">
                <Info className="profile-form__info">
                    Для прохождения модерации нужно заполнить: контакты (имя, телефон, сайт компании) и юридический адрес
                </Info>

                <div className="profile-form__form form">
                    <div className="form__row">
                        <TextInput
                            isRequired
                            isReadOnly
                            label="Наименование юр. лица"
                            value={companyLegalName}
                        />

                        <TextInput
                            isRequired
                            isReadOnly
                            label="ИНН"
                            value={companyInn}
                        />
                    </div>
                    <div className="form__row">
                        <TextInput
                            isRequired
                            isReadOnly
                            label="E-mail"
                            value={userEmail}
                        />

                        <TextInput
                            name="kpp"
                            label="КПП"
                            value={companyKpp || values.kpp}
                            onChange={onValueChanged}
                        />
                    </div>
                    <div className="form__row">
                        <TextInput
                            name="phone"
                            label="Телефон"
                            placeholder="Введите номер телефона"
                            value={userPhone || values.phone}
                            onChange={onValueChanged}
                        />

                        <TextInput
                            name="businessArea"
                            label="Сфера деятельности"
                            value={companyBusinessArea || values.businessArea}
                            onChange={onValueChanged}
                        />
                    </div>
                    <div className="form__row">
                        <TextInput
                            name="fulleName"
                            label="ФИО представителя"
                            value={userFullName || values.fullName}
                            onChange={onValueChanged}
                        />
                        <TextInput
                            name="website"
                            label="Сайт компании"
                            placeholder="Введите ссылку"
                            value=""
                            onChange={onValueChanged}
                        />
                    </div>

                    <div className="form__row">
                        <TextInput
                            name="legalAddress"
                            label="Юридический адрес"
                            value={companyLegalAddress || values.legalAddress}
                            onChange={onValueChanged}
                        />
                    </div>
                </div>
            </div>

            <div className="profile-form__actions">
            <Button isWhite title="Отмена" onClick={onCancelButtonClick} />
                <Button title="Сохранить" onClick={onSaveButtonClick} />
            </div>
        </div>
    );
}

export default ProfileForm;
