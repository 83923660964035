import axios from 'axios';

export interface IPartnerUser {

}

class PartnerAPI {
    user = (data: IPartnerUser) => {
        return axios.post('/partner/user', data);
    }

}

const partnerAPI = new PartnerAPI();
export default partnerAPI;