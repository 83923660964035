import axios from 'axios';

export interface IProfileSaveRequest {
    kpp?: string;
    phone?: string;
    businessArea?: string;
    fullName?: string;
    website?: string;
    legalAddress?: string;
}

class ProfileAPI {
    me = () => {
        return axios.get('/profile');
    }

    update = (companyId: number, values: IProfileSaveRequest) => {
        return axios.post(`/profile/update?companyId=${companyId}`, values);
    }
}

const profileAPI = new ProfileAPI();
export default profileAPI;