import React from "react";
import classNames from "classnames";
import { IPageTitleProps } from "./types/PageTitle.types";
import Button from "components/UI/Button/Button";

import "./styles/PageTitle.sass"

const PageTitle = (props: IPageTitleProps) => {
    const { title, className, hasButton, buttonTitle, buttonAction } = props;

    return (
        <div className="page-title__wrapper">
            <h1 className={classNames('page-title__title', className)}>{title}</h1>
            {hasButton && (<Button className="page-title__button" onClick={buttonAction} title={buttonTitle} />)}
        </div>
    );
};

export default PageTitle;