import { atom, selector } from "recoil";

export interface IProfileState {
    userId?: number;
    firstName?: string,
    middleName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    companyId?: number;
    balance?: number;
}

export const profileState = atom<IProfileState>({
    key: "profileState",
    default: {
    }
})

export const getUserId = selector<undefined | number>({
    key: "userId",
    get: ({ get }) => {
        return get(profileState).userId;
    }
});

export const getUserFirstName = selector<undefined | string>({
    key: "userFirstName",
    get: ({ get }) => {
        return get(profileState).firstName;
    }
});

export const getUserMiddleName = selector<undefined | string>({
    key: "userMiddleName",
    get: ({ get }) => {
        return get(profileState).middleName;
    }
});

export const getUserLastName = selector<undefined | string>({
    key: "userLastName",
    get: ({ get }) => {
        return get(profileState).lastName;
    }
});

export const getUserFullName = selector<undefined | string>({
    key: "userFullName",
    get: ({ get }) => {
        const { firstName, middleName, lastName, email } = get(profileState);
        let fullName =  [lastName, firstName, middleName].join(' ');
        return (fullName.trim() !== '') ? fullName : email;
    }
});

export const getUserEmail = selector<undefined | string>({
    key: "userEmail",
    get: ({ get }) => {
        return get(profileState).email;
    }
});

export const getUserPhone = selector<undefined | string>({
    key: "userPhone",
    get: ({ get }) => {
        return get(profileState).phone;
    }
});

export const getUserCompanyId = selector<undefined | number>({
    key: "userCompanyId",
    get: ({ get }) => {
        return get(profileState).companyId;
    }
});

export const getUserBalance = selector<number>({
    key: "userBalance",
    get: ({ get }) => {
        return get(profileState).balance || 0;
    }
});