import React from 'react';
import "../styles/Page.sass";
import "./styles/CampaignCreatePage.sass";
import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import CampaignForm from "../../../components/Campaign/CampaignForm/CampaignForm";

const CampaignCreatePage = () => {
    return (
        <div className="page campaign-create">
            <PageTop className="campaign-create__top">
                <PageTitle
                    className="campaign-create__title"
                    title="Создание рекламной кампании"
                />
            </PageTop>

            <CampaignForm />
        </div>
    );
}

export default CampaignCreatePage;
