import React from 'react';
import Grid from "../../UI/Grid/Grid";
import {
    IGridDataRowsState,
    IGridHeaderCellProps
} from "../../UI/Grid/types/Grid.types";

import "./styles/FinancesHistory.sass";

const headers: IGridHeaderCellProps[] = [
    {
        column: 'title',
        title: 'Номер'
    },
    {
        column: 'date',
        title: 'Дата'
    },
    {
        column: 'status',
        title: 'Статус'
    },
    {
        column: 'amount',
        title: 'Сумма'
    },
];

const data: IGridDataRowsState[] = [
    [
        {
            column: 'title',
            value: '533'
        },
        {
            column: 'date',
            value: '20.07.2023'
        },
        {
            column: 'status',
            value: 'Активно'
        },
        {
            column: 'amount',
            value: '2000.00₽'
        },
    ],
];

const FinancesHistory = () => {
    return (
        <div className="finances-history">
            <div className="finances-history__switch">
                <button className="finances-history__button active" type="button">Пополнения</button>
                <button className="finances-history__button" type="button">Списания</button>
            </div>

            <Grid
                className="finances-history__grid"
                columnsTemplate="1fr minmax(1fr, auto) minmax(1fr, auto) minmax(1fr, auto) 200px"
                headers={headers}
                data={data} />
        </div>
    );
}

export default FinancesHistory;
