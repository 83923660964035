import React from 'react';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import { useRecoilState, useRecoilValue } from "recoil";
import { getIsProfileModalOpened, modalState, ModalTypes } from "../../store/Modal.recoil";
import { getUserBalance } from "../../store/Profile.recoil";
import { prettifyMoneyValue } from "../../utils/functions";

import WalletFilledIcon from "../UI/SvgIcons/WalletFilledIcon";
import ProfileIcon from "../UI/SvgIcons/ProfileIcon";

import './styles/Header.sass';

const Header = () => {
    const [, setModal] = useRecoilState(modalState);
    const isProfileModalOpened = useRecoilValue(getIsProfileModalOpened);

    const userBalance = useRecoilValue(getUserBalance);

    const onProfileModalToggle = () => {
        setModal({
            type: ModalTypes.profile,
            isOpened: !isProfileModalOpened,
        })
    }

    return (
        <div className="header">
            <a className="header__logo" href="/">
                <LogoIcon/>
            </a>

            <div className="header__nav">
                <div className="header__finances">
                    <WalletFilledIcon />
                    <div
                        className="header__balance"
                        dangerouslySetInnerHTML={{__html: prettifyMoneyValue(userBalance) }}
                    ></div>
                </div>
                <button className="header__profile" type="button" onClick={onProfileModalToggle}>
                    <ProfileIcon />
                </button>
            </div>
        </div>
    )
}

export default Header;