import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const WalletFilledIcon = (props: ISvgIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5782 1.03677C18.2115 0.175513 17.2066 -0.22937 16.3336 0.132479L7.71429 3.70532H19.7143L18.5782 1.03677Z"
                fill="#4E5E6D"/>
            <path
                d="M3.42857 4.55093C1.53502 4.55093 0 6.06531 0 7.93338V20.6176C0 22.4856 1.53502 24 3.42857 24H20.5714C22.465 24 24 22.4856 24 20.6176V16.3895H18C16.5798 16.3895 15.4286 15.2537 15.4286 13.8527C15.4286 12.4516 16.5798 11.3158 18 11.3158H24V7.93338C24 6.06531 22.465 4.55093 20.5714 4.55093H3.42857Z"
                fill="#4E5E6D"/>
            <path
                d="M19.7143 13.8527C19.7143 14.7867 18.9468 15.5439 18 15.5439C17.0532 15.5439 16.2857 14.7867 16.2857 13.8527C16.2857 12.9186 17.0532 12.1614 18 12.1614C18.9468 12.1614 19.7143 12.9186 19.7143 13.8527Z"
                fill="#4E5E6D"/>
        </svg>
    );
};

export default WalletFilledIcon;