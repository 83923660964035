import React, { useState } from "react";
import "./styles/CampaignForm.sass";
import classNames from "classnames";
import TextInput from "../../UI/Inputs/TextInput/TextInput";
import Button from "../../UI/Button/Button";
import DateRangeInput from "../../UI/Inputs/DateRangeInput/DateRangeInput";
import Info from "../../UI/Info/Info";

enum TAB {
    COMMON= 'common',
    TARGETING = 'targeting',
    BUDGET = 'budget',
}
interface ITab {
    id: TAB;
    title: string;
    group: string;
}
const TABS: ITab[] = [
    {
        id: TAB.COMMON,
        title: '1. Основная информация',
        group: 'Основная информация'
    },
    {
        id: TAB.TARGETING,
        title: '2. Аудитория и таргетинг',
        group: 'Аудитория и таргетинг'
    },
    {
        id: TAB.BUDGET,
        title: '3. Бюджет и показы',
        group: 'Бюджет и показы'
    }
]

const CampaignForm = () => {
    const [selectedTabId, setSelectedTabId] = useState(TAB.COMMON);

    const onTabClick = (tabId: TAB): void => {
        setSelectedTabId(tabId);
    }

    const getFieldsGroupName = (): string => {
        const selectedTab = TABS.find((tab) => tab.id === selectedTabId);
        return selectedTab?.group || '';
    }

    return (
        <div className="campaign-create-form">
            <div className="campaign-create-form__tabs">
                {TABS.map((tab, i) => (
                    <div
                        key={tab.id}
                        className={classNames("campaign-create-form__tab", { 'active': tab.id === selectedTabId })}
                        onClick={(e) => onTabClick(tab.id)}
                    >{ tab.title }</div>
                ))}
            </div>
            <div className="campaign-create-form__group">
                <div className="campaign-create-form__title">{ getFieldsGroupName() }</div>

                {selectedTabId === TAB.COMMON && (
                    <div className="campaign-create-form__fields">
                        <TextInput
                            label="Название"
                            placeholder="Введите название реклам. кампании"
                        />

                        <div className="campaign-create-form__field">
                            <DateRangeInput
                                label="Период действия рекламной компании"
                            />

                            <Info className="campaign-create-form__info">
                                Дата окончания Рекламной Кампании не является обязательной. В случае если Вы не
                                установили Дату окончания, Рекламная Кампания будет активна до тех пор, пока вы ее не
                                остановите. Дата старта начинается с 00:00 часов. Дата завершения - до 23:59:59
                            </Info>
                        </div>

                        <div className="campaign-create-form__field">
                            <TextInput
                                label="Ссылка перехода"
                                placeholder="Вставьте ссылку для перехода"
                            />

                            <Info className="campaign-create-form__info">
                                Внимание! На указанную ссылку будет перенаправлен пользователь при клике на Вашу рекламу
                            </Info>
                        </div>
                    </div>
                )}
            </div>
            <div className="campaign-create-form__actions">
                <Button title="Продолжить" />
            </div>
        </div>
    )
}

export default CampaignForm;