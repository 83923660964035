import React from 'react';
import { NavLink, NavLinkRenderProps } from "react-router-dom";
import { ISidebarItemProps } from "./types/Sidebar.types";
import StarIcon from "../UI/SvgIcons/StarIcon";
import ClientsIcon from "../UI/SvgIcons/ClientsIcon";
import LockOpenedIcon from "../UI/SvgIcons/LockOpenedIcon";
import WalletIcon from "../UI/SvgIcons/WalletIcon";
import UserIcon from "../UI/SvgIcons/UserIcon";
import MessageIcon from "../UI/SvgIcons/MessageIcon";

import './styles/Sidebar.sass';

const sidebarLinks: ISidebarItemProps[] = [
    {
        icon: <UserIcon />,
        title: "Профиль",
        href: "/profile"
    },
    {
        icon: <StarIcon />,
        title: "Мои рекламные кампании",
        href: "/campaigns"
    },
    {
        icon: <ClientsIcon />,
        title: "Клиенты",
        href: "/clients"
    },
    {
        icon: <LockOpenedIcon />,
        title: "Доступы",
        href: "/access"
    },
    {
        icon: <WalletIcon />,
        title: "Пополнение бюджета",
        href: "/finances"
    },
    {
        icon: <StarIcon />,
        title: "Статистика",
        href: "/statistics"
    },
    {
        icon: <MessageIcon />,
        title: "Поддержка",
        href: "/support"
    },
]

const Sidebar = () => {

    const getNavLinkClass = ({ isActive, isPending }: NavLinkRenderProps): string => {
        return "sidebar__link link " + (isActive ? "active" : isPending ? "pending" : "");
    }

    return (
        <div className="sidebar">
            <nav className="sidebar__nav">
                {sidebarLinks.map((item: ISidebarItemProps, i: number) => (
                    <div className="sidebar__item" key={i}>
                        <NavLink to={item.href} className={getNavLinkClass}>
                            {item.icon && <span className="link__icon">{item.icon}</span>}
                            <span className="link__title">{item.title}</span>
                        </NavLink>
                    </div>
                ))}
            </nav>
        </div>
    )
}

export default Sidebar;