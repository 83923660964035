import React from "react";
import { ISvgIconProps } from "./types/SvgIcon.types";

const StarIcon = (props: ISvgIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.99999 1C7.96385 1 7.77134 4.54652 6.55911 5.79957C5.34689 7.05263 1.5782 5.61992 1.05459 7.84403C0.532075 10.0693 3.92235 10.8243 4.34036 12.7334C4.76057 14.6426 2.68922 17.3249 4.45916 18.6598C6.22911 19.9936 8.13435 16.9747 9.99999 16.9747C11.8656 16.9747 13.7709 19.9936 15.5408 18.6598C17.3108 17.3249 15.2405 14.6426 15.6596 12.7334C16.0787 10.8243 19.4679 10.0693 18.9454 7.84403C18.4229 5.61992 14.6531 7.05263 13.442 5.79957C12.2297 4.54652 12.0361 1 9.99999 1Z"
                  stroke="#4E5E6D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default StarIcon;