import React from 'react';

import "../styles/Page.sass";

import PageTop from "../../../components/PageTop/PageTop";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ClientsFilters from "../../../components/Client/ClientsFilters/ClientsFilters";
import { useRecoilState, useRecoilValue } from "recoil";
import { getIsCreateClientModalOpened, modalState, ModalTypes } from "../../../store/Modal.recoil";

const ClientsPage = () => {
    const [, setModal] = useRecoilState(modalState);
    const isCreateClientModalOpened = useRecoilValue(getIsCreateClientModalOpened);

    const onButtonClick = () => {
        setModal({
            type: ModalTypes.createClient,
            isOpened: !isCreateClientModalOpened,
        })
    }

    return (
        <div className="page clients">
            <PageTop className="clients__top">
                <PageTitle
                    className="clients__title"
                    title="Клиенты"
                    hasButton
                    buttonTitle="Создать кабинет клиента"
                    buttonAction={onButtonClick}
                />

                <ClientsFilters />
            </PageTop>
        </div>
    );
}

export default ClientsPage;
