import axios from 'axios';
import {ClientFormType} from "../../models/ClientFormTypes";

export interface ICompanySaveRequest {
    formType: ClientFormType;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    legalName?: string;
    inn: string;
    contract: string,
    comment?: string;
}

class CompanyAPI {
    create = (values: ICompanySaveRequest) => {

    }

    info = (companyId: number) => {
        return axios.get(`/company?companyId=${companyId}`);
    }
}

const companyAPI = new CompanyAPI();
export default companyAPI;