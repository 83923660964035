import React from 'react';
import PageTop from "components/PageTop/PageTop";
import PageTitle from "components/UI/PageTitle/PageTitle";
import ProfileForm from "components/Profile/ProfileForm/ProfileForm";

import "../styles/Page.sass";

const ProfilePage = () => {
    return (
        <div className="page profile-page">
            <PageTop className="profile-page__top">
                <PageTitle
                    className="profile-page__title"
                    title="Основная информация"
                />
            </PageTop>

            <ProfileForm />
        </div>
    );
}

export default ProfilePage;
